<template>
  <div>
    <Home />
    <About />
  </div>
</template>

<script setup>
import { useDisplay } from 'vuetify'

// Destructure only the keys you want to use
const { smAndDown, mdOnly } = useDisplay()
</script>

<style scoped>
</style>
