<template>
  <!-- home section -->
  <section id="hero">
    <div :style="backgroundStyles" class="banner-wrapper">
      <v-container :class="smAndDown ? 'mt-12' : undefined">
        <v-row justify="center">
          <v-col cols="12" sm="6" class="d-flex align-center">
            <div class="text-center">
              <nuxt-img
                src="/img/orthofresh-logo.webp"
                format="webp"
                quality="100"
                :placeholder="[350, 155, 50, 1]"
                width="350px"
                alt="OrthoFresh Logo"
                class="mx-auto mb-n6"
              />
              <h1 class="font-weight-light text-accent">
                The cold water cleaner for ALL of today's oral appliances!
              </h1>
              <v-btn
                rounded="xl"
                variant="outlined"
                class="mt-5 mr-2"
                color="primary"
                aria-label="Go to Features Button"
                @click="goToLink('features')"
              >
                Availability
                <v-icon class="ml-2">
                  mdi-arrow-down
                </v-icon>
              </v-btn>
              <v-btn
                rounded="xl"
                class="mt-5"
                color="primary"
                href="/documents/pressRelease.pdf"
                target="_blank"
                aria-label="Load Press Release"
                dark
              >
                Press Release
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="text-center">
              <nuxt-img
                src="/img/bottle-splash.webp"
                format="webp"
                quality="100"
                :placeholder="[525, 398, 50, 1]"
                alt="Bottle Splash OrthoFresh"
                width="525"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container id="features" fluid class="mt-2">
      <v-row justify="space-around" class="mx-md-0 mx-lg-6" align="stretch">
        <!-- USA Cards -->
        <v-col cols="12" md="6" class="text-center">
          <v-card class="card" elevation="4">
            <v-card-title>
              <v-spacer />
              <span class="text-overline font-weight-bold">Authorized USA Distributors</span>
              <v-spacer />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col>
                  <nuxt-img
                    src="/img/usa-flag.webp"
                    format="webp"
                    quality="80"
                    :placeholder="[80, 80, 50, 1]"
                    width="80px"
                    class="d-block ml-auto mr-auto mb-2"
                    alt="USA Flag"
                  />
                  <h1 class="font-weight-bold text-medium-emphasis">
                    For Consumers
                  </h1>
                  <h4 class="font-weight-regular text-subtitle-1 text-medium-emphasis">
                    For USA customers looking to purchase their own Ortho Fresh® for personal use, please visit:<br>
                    <a href="https://www.patientsleepsupplies.com/orthofresh-oral-appliance-cleanerhtml" target="_blank">PatientSleepSupplies</a>
                  </h4>
                </v-col>
                <v-divider vertical />
                <v-col>
                  <nuxt-img
                    src="/img/usa-flag.webp"
                    format="webp"
                    quality="80"
                    :placeholder="[80, 80, 50, 1]"
                    width="80px"
                    class="d-block ml-auto mr-auto mb-2"
                    alt="USA Flag"
                  />
                  <h1 class="font-weight-bold text-medium-emphasis">
                    For Professionals
                  </h1>
                  <h4 class="font-weight-regular text-subtitle-1 text-medium-emphasis">
                    For USA professionals looking to purchase Ortho Fresh®, please visit:<br>
                    <a href="https://www.mvapmed.com/products/search/orthofresh" target="_blank">MVAP</a> (qty &lt; 50)
                    <br>For bulk orders please contact us at <a href="tel:18884624841">1-888-462-4841</a>
                  </h4>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Canadian Cards -->
        <v-col cols="12" md="6" class="text-center">
          <v-card class="card fill-height" elevation="4">
            <v-card-title>
              <v-spacer />
              <span class="text-overline font-weight-bold">Authorized Canadian Distributors</span>
              <v-spacer />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col>
                  <nuxt-img
                    src="/img/canada-flag.webp"
                    format="webp"
                    quality="80"
                    :placeholder="[80, 80, 50, 1]"
                    width="80px"
                    class="d-block ml-auto mr-auto mb-2"
                    alt="Canada Flag"
                  />
                  <h1 class="font-weight-bold text-medium-emphasis">
                    For Consumers
                  </h1>
                  <h4 class="font-weight-regular text-subtitle-1 text-medium-emphasis">
                    For Canadian customers looking to purchase their own Ortho Fresh® for personal use, please visit:<br>
                    <a href="https://www.amazon.ca/OrthoFresh-Professional-Dental-Appliance-Cleaner/dp/B0BLWF93GF" target="_blank">Amazon.ca</a>
                    or
                    <a href="https://www.costco.ca/orthofresh-dental-appliance-cleaner%2C-2-x-340-g.product.4000271784.html" target="_blank">Costco.ca</a>
                  </h4>
                </v-col>
                <v-divider vertical />
                <v-col>
                  <nuxt-img
                    src="/img/canada-flag.webp"
                    format="webp"
                    quality="80"
                    :placeholder="[80, 80, 50, 1]"
                    width="80px"
                    class="d-block ml-auto mr-auto mb-2"
                    alt="Canada Flag"
                  />
                  <h1 class="font-weight-bold text-medium-emphasis">
                    For Professionals
                  </h1>
                  <h4 class="font-weight-regular text-subtitle-1 text-medium-emphasis">
                    For Canadian professionals looking to purchase Ortho Fresh®, please visit:<br>
                    <a href="https://store2.braebon.com/products/oralappliances/3590" target="_blank">Braebon Medical</a>
                  </h4>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="svg-border-waves">
        <img src="/img/wave2.svg" alt="Border Shapes">
      </div>
    </v-container>
  </section>
</template>

<script setup>
import { useDisplay } from 'vuetify'
const img = useImage()
const backgroundStyles = computed(() => {
  const imgUrl = img('/img/banner_background.webp', { height: 425 }, { format: 'webp', quality: 100, height: 425, placeholder: [425, 425, 50, 1] })
  return { backgroundImage: `url('${imgUrl}')` }
})

// Destructure only the keys you want to use
const { smAndDown, mdOnly } = useDisplay()
  function goToLink (link) {
    const element = document.getElementById(link);
    element.scrollIntoView({ behavior: "smooth" });
  }
</script>

<style scoped>
.banner-wrapper {
  background-color: #f2f7fc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /*
    background: linear-gradient(0deg, rgba(244,244,244,1) 0%, rgba(240,248,255,1) 100%);
  */
  min-height: 350px;
  display: flex;
  align-items: center;
}
#hero {
  z-index: 0;
}
.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}

section {
  position: relative;
}

.hero {
  width: 100%;
  background-color: #026597;
}

.sectionDividerHeader {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 25px !important;
  line-height: 100% !important;
  letter-spacing: -0.02em !important;
  color: #FFFFFF;
}
</style>
